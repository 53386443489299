import modalStyle from "../../styles/components/Modal.module.scss";

import Close from "../../images/ui-plus-close.svg";

type Props = {
    title: string;
    setShowModal: any;
    embed: any;
    videoURL?: any;
};

function VideoModal({ title, setShowModal, embed, videoURL }: Props) {
    return (
        <div className={`${modalStyle.dialogLayer} ${modalStyle.mediaModal}`}>
            <div className={`${modalStyle.dialogLayerInner}`}>
                <div className={`${modalStyle.background}`} onClick={() => {
                    setShowModal(false);
                }}></div>
                <div role={'dialog'} id={'folder-dialog'} aria-modal={true} className={`${modalStyle.dialogBox} ${modalStyle.mediaContainer}`}>
                    <div className={`${modalStyle.headerContainer}`}>
                        <h2>{title}</h2>
                        <button className={`${modalStyle.closeBtn}`} onClick={() => {
                            setShowModal(false);
                        }}>
                            <span>Close</span>
                            <Close />
                        </button>
                    </div>
                    <div className={modalStyle.mediaContent}>
                        <div className={modalStyle.videoContainer}>
                            {videoURL ? (
                                <video width={'560'} height={'315'} muted autoPlay controls>
                                    <source src={videoURL} type={`video/mp4`} />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <iframe width="560" height="315" src={embed}
                                    title="video player" frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen></iframe>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoModal;