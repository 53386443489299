import React, { useState, useEffect, useRef } from "react";
import parse, { DOMNode, domToReact } from "html-react-parser";
import Image from "next/image";

// Functions
import { processBannerImage } from "helpers/functions";

// Styles
import styles from "styles/components/feel-good-design/Banner.module.scss";

type Props = {
    data?: any;
    field_size?: any;
};

const BannerContent = ({ data }: Props) => {
    if (data) {
        let media_type: any = 'image';
        let file_ext: any = '';
        if (data.banner) {
            file_ext = data.banner.split('.').pop();
            if (file_ext === 'mp4' || file_ext == 'mov') {
                media_type = 'video'
            }
        }
        let textAlignment: any = '';
        if(data.text_alignment === 'left') {
            textAlignment = styles.left;
        } else if (data.text_alignment === 'right') {
            textAlignment = styles.right;
        }
        return (
            <div className={styles.bannerContent}>
                {data.banner && (
                    <div className={styles.imageContainer}>
                        <div className={styles.imageContainerInner}>
                            {media_type === 'video' ? (
                                <video
                                    muted={true}
                                    autoPlay={true}
                                    loop={true}
                                    playsInline={true}
                                    preload="auto"
                                    className="home-animation img-fluid">
                                    <source
                                        src={processBannerImage(data.banner, true)}
                                        type={`video/${file_ext}`}
                                    />
                                </video>
                            ) : (
                                <>
                                    {data.banner && (
                                        <Image
                                            src={data.banner.includes('https://') ? data.banner : processBannerImage(data.banner, true)}
                                            alt={''}
                                            width={2}
                                            height={1}
                                            layout="responsive"
                                            unoptimized={true}
                                            objectFit="cover"
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}

                {data.text && (
                    <div className={`${styles.textContainer}  ${textAlignment}`}>
                        {parse(data.text)}
                    </div>
                )}
            </div>
        )
    } else {
        return <></>
    }
}

export default BannerContent;