import React, { useState, useEffect, useRef } from "react";
import parse, { DOMNode, domToReact } from "html-react-parser";
import { createPortal } from "react-dom";
import VideoModal from "components/creative-effectiveness/VideoModal";

// Next
import Image from "next/image";

// Styles
import styles from "styles/components/feel-good-design/Slider.module.scss";

// Functions
import { processBannerImage } from "helpers/functions";

// Images
import NavArrow from "images/ui-design-banner-nav.svg";
import Pause from "images/ui-pause.svg";
import Play from "images/ui-play.svg";

// Import Swiper React components
import {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	Autoplay
} from 'swiper/modules';

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

type Props = {
	content?: any;
};

const Modal = (props: any) => {
	const [container] = React.useState(() => {
		// This will be executed only on the initial render
		// https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
		return document.createElement('div');
	});

	React.useEffect(() => {
		container.classList.add('root-protal')
		document.body.appendChild(container)
		return () => {
			document.body.removeChild(container)
		}
	}, [])

	return (
		<>
			{createPortal(
				props.children,
				container,
			)}
		</>
	);
}

const VideoEl = (props: any) => {
	const thumbnail: any = props.thumbnail;
	const video: any = props.video;
	const [showModal, setShowModal] = useState(false);

	return (
		<div className={styles.thumbnailContainer}>
			<button onClick={() => {
				setShowModal(true);
			}} className={styles.playButton}>
				<span>View Now</span>
			</button>
			<Image
				unoptimized={true}
				src={thumbnail ? thumbnail : ''}
				alt={''}
				layout="fill"
				objectFit="cover"
			/>˝
			{showModal && (
				<Modal>
					<VideoModal
						title={''}
						setShowModal={setShowModal}
						embed={null}
						videoURL={video}
					/>
				</Modal>
			)}
		</div>
	)
}

const BannerImageSlider = (props: any) => {
	const slides: any = props.slides;
	const imageSwiperRef: any = props.imageSwiperRef;
	const textSwiperRef: any = props.textSwiperRef;
	const setImageSwiper: any = props.setImageSwiper;

	return (
		<Swiper
			effect={'fade'}
			modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
			spaceBetween={0}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
			className={styles.imageSwiper}
			ref={imageSwiperRef}
			onSlideChange={(virtualIndex: any) => {
				if (textSwiperRef.current) {
					textSwiperRef.current.swiper.slideTo(virtualIndex.activeIndex);
				}
			}}
			onSwiper={setImageSwiper}
		>
			{slides.map((content: any, index: any) => {
				return (
					<SwiperSlide key={`lead-image-slide-${index}`}>
						{content.attributes.file ? (
							<>
								{content.attributes.file && content.attributes.file.includes('.mp4') ? (
									<VideoEl
										thumbnail={processBannerImage(content.attributes.thumbnail, true)}
										video={processBannerImage(content.attributes.file, true)}
									/>
								) : (
									<Image
										unoptimized={true}
										src={processBannerImage(content.attributes.file, true)}
										alt={content.attributes.title ? content.attributes.title : ''}
										layout="fill"
										objectFit="cover"
									/>
								)}
							</>
						) : content.attributes.thumbnail ? (
							<Image
								unoptimized={true}
								src={processBannerImage(content.attributes.thumbnail, true)}
								alt={content.attributes.title ? content.attributes.title : ''}
								layout="fill"
								objectFit="cover"
							/>
						) : (
							<></>
						)}

					</SwiperSlide>
				)
			})}
		</Swiper>
	)
}

const SliderContent = ({ content }: Props) => {
	const textSwiperRef: any = useRef(null);
	const paginationRef: any = useRef(null);
	const navigationPrevRef: any = useRef(null);
	const navigationNextRef: any = useRef(null);
	const [mainSwiper, setMainSwiper] = useState<any>(null);
	const [activeNum, setActiveNum] = useState<any>(1);
	const [paused, setPaused] = useState<boolean>(false);
	const pageURL:string = window.location.host;

	function togglePause() {
		paused ? setPaused(false) : setPaused(true);
	}

	// Image Slider
	const imageSwiperRef: any = useRef(null);
	const [imageSwiper, setImageSwiper] = useState<any>(null);

	// Use state for current window width
	const [width, setWidth] = useState(0);

	// When component renders or window is resized, grab new window width
	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	if (content.slides && content.slides.length > 0) {
		return (
			<div className={`${styles.sliderContent} ${styles.carouselContainer}`}>
				<div className={styles.wrapper}>
					{width <= 700 && (
						<BannerImageSlider
							slides={content.slides}
							imageSwiperRef={imageSwiperRef}
							textSwiperRef={textSwiperRef}
							setImageSwiper={setImageSwiper}
						/>
					)}
					<Swiper
						className={styles.textSwiper}
						effect={'fade'}
						modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
						spaceBetween={0}
						pagination={{
							el: paginationRef.current,
							type: 'progressbar',
						}}
						autoplay={{
							delay: 5000,
							disableOnInteraction: false,
						}}
						navigation={{
							prevEl: navigationPrevRef.current,
							nextEl: navigationNextRef.current,
						}}
						onBeforeInit={(swiper: any) => {
							swiper.params.navigation.prevEl = navigationPrevRef.current;
							swiper.params.navigation.nextEl = navigationNextRef.current;
							swiper.params.pagination.el = paginationRef.current;
						}}
						onSlideChange={(virtualIndex: any) => {
							if (imageSwiperRef.current) {
								imageSwiperRef.current.swiper.slideTo(virtualIndex.activeIndex);
								setActiveNum(virtualIndex.activeIndex + 1);
							}
						}}
						ref={textSwiperRef}
						onSwiper={setMainSwiper}
					>
						{content.slides.map((content: any, index: any) => {
							return (
								<SwiperSlide key={`lead-text-slide-${index}`}>
									<div className={styles.textContainer}>
										{content.attributes.title && (
											<p className={styles.subTitle}>{content.attributes.title}</p>
										)}
										{content.attributes.description && (
											<div className={styles.description}>{parse(content.attributes.description)}</div>
										)}
										{content.attributes.cta_url && content.attributes.cta_url.includes(pageURL) && (
											<a href={content.attributes.cta_url} className={'btn secondary'}>{content.attributes.cta_text ? content.attributes.cta_text : content.attributes.cta_url}</a>
										)}
										{content.attributes.cta_url && !content.attributes.cta_url.includes(pageURL) && (
											<a target="_blank" rel="noopener noreferrer" href={content.attributes.cta_url} className={'btn secondary'}>{content.attributes.cta_text ? content.attributes.cta_text : content.attributes.cta_url}</a>
										)}									
									</div>
								</SwiperSlide>
							)
						})}
						{content.slides.length > 1 && (
							<div className={`navigation ${styles.navigation}`}>
								<button className={`swiper-button-prev ${styles.swiperSlidePrev}`} ref={navigationPrevRef}>
									<NavArrow />
									<span>View Prev Slide</span>
								</button>
								<p className={styles.slideNumber}>{activeNum < 10 ? `0${activeNum}` : activeNum}</p>
								<div className={`swiper-pagination ${styles.swiperPagination}`} ref={paginationRef}></div>
								<p className={styles.slideNumber}>{content.slides.length < 10 ? `0${content.slides.length}` : content.slides.length}</p>
								<button className={`swiper-button-next ${styles.swiperSlideNext}`} ref={navigationNextRef}>
									<NavArrow />
									<span>View Next Slide</span>
								</button>
								<button className={'pause-button'} onClick={(e: any) => {
									e.preventDefault();
									if (mainSwiper) {
										if (paused) {
											mainSwiper.autoplay.start();
											imageSwiper.autoplay.start();
										} else {
											mainSwiper.autoplay.stop();
											imageSwiper.autoplay.stop();
										}
									}
									togglePause();
								}}>
									{paused ? (
										<>
											<Play />
											<span>Play</span>
										</>
									) : (
										<>
											<Pause />
											<span>Pause</span>
										</>
									)}
								</button>
							</div>
						)}
					</Swiper>
					{width > 700 && (
						<BannerImageSlider
							slides={content.slides}
							imageSwiperRef={imageSwiperRef}
							textSwiperRef={textSwiperRef}
							setImageSwiper={setImageSwiper}
						/>
					)}
				</div>
			</div>
		)
	} else {
		return (
			<></>
		)
	}
}

export default SliderContent;