import React, { useState, useEffect, useRef } from "react";
import parse, { DOMNode, domToReact } from "html-react-parser";
import styles from "styles/pages/feel-good-design/FeelGoodDesign.module.scss";

// Functions
import { processBannerImage } from "helpers/functions";

// Images
import IconDownload from "images/ui-download-arrow.svg";

type Props = {
    files: any;
};

const FilesContent = ({ files }: Props) => {
    return (
        <div className={`${styles.gridContent} ${styles.content}`}>
            {files && files.length > 0 && files.map((item: any, index: any) => {
                const fileURL: any = item.attributes.attachments ? processBannerImage(item.attributes.attachments, true) : null;
                return (
                    <div key={`grid-item-${index}`} className={`${styles.gridItem}`} data-size={item.attributes.field_size ? item.attributes.field_size : ''}>
                        <a download={fileURL ? true : false} href={fileURL ? fileURL : item.attributes.cta_url} target={'_blank'} className={styles.downloadContainer}>
                            {item.attributes.thumbnail && (
                                <div className={`${styles.imageContainer} ${item.attributes.add_border ? 'hasBorder' : ''}`}>
                                    <img src={item.attributes.thumbnail.includes('https://') ? item.attributes.thumbnail : processBannerImage(item.attributes.thumbnail.replace('//', '/'), true)} alt={''} />
                                    <IconDownload />
                                </div>
                            )}
                            <div className={styles.textContainer}>
                                {item.attributes.title && (
                                    <h3>{item.attributes.title}</h3>
                                )}
                                {item.attributes.caption && (
                                    <p>{item.attributes.caption}</p>
                                )}
                            </div>
                        </a>
                        {item.attributes.cta_url && (
                            <a href={item.attributes.cta_url} className={styles.cta} target={'_blank'}>{item.attributes.cta_text ? item.attributes.cta_text : item.attributes.cta_url}</a>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default FilesContent;