import parse, { DOMNode, domToReact } from "html-react-parser";

import styles from "styles/pages/feel-good-design/FeelGoodDesign.module.scss";

type Props = {
    content?: any;
    field_size?: any;
};

const TableContent = ({content, field_size}: Props) => {
    return (
        <div className={styles.content} data-size={field_size ? field_size : '100'}>
            {content && (
                 <>{parse(content)}</>
            )}
        </div>
    )
}

export default TableContent;