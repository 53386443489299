import React, { useState, useEffect, useRef } from "react";
import parse, { DOMNode, domToReact } from "html-react-parser";

// Styles
import styles from "styles/components/feel-good-design/Carousel.module.scss";

// Images
import NavArrow from "images/ui-swiper-arrow.svg";
import Pause from "images/ui-pause.svg";
import Play from "images/ui-play.svg";

// Import Swiper React components
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay
} from 'swiper/modules';

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

type Props = {
    content?: any;
    field_size?: any;
};

const CarouselContent = ({ content, field_size }: Props) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const pagRef = useRef(null);
    const numRef = useRef(null);
    const [activeNum, setActiveNum] = useState<any>(1);
    const [contentSwiper, setContentSwiper] = useState<any>(null);
    const [paused, setPaused] = useState<boolean>(false);

    function togglePause() {
        paused ? setPaused(false) : setPaused(true);
    }

    if (content && content.length > 0) {
        return (
            <div className={`${styles.content} ${styles.carouselContainer}`} data-size={field_size ? field_size : '100'}>
                <div className={styles.swiperNavigation}>
                    <button ref={prevRef} className={'swiper-button-prev'}>
                        <span>Previous Slide</span>
                        <NavArrow />
                    </button>
                    <p className={styles.slideNum} ref={numRef}>{activeNum}</p>
                    <button ref={nextRef} className={'swiper-button-next'}>
                        <span>Next Slide</span>
                        <NavArrow />
                    </button>
                    <button className={styles.pauseButton} onClick={(e: any) => {
                        e.preventDefault();
                        if (contentSwiper) {
                            if (paused) {
                                contentSwiper.autoplay.start();
                            } else {
                                contentSwiper.autoplay.stop();
                            }
                        }
                        togglePause();
                    }}>
                        {paused ? (
                            <>
                                <Play />
                                <span>Play</span>
                            </>
                        ) : (
                            <>
                                <Pause />
                                <span>Paused</span>
                            </>
                        )}
                    </button>
                </div>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    pagination={{
                        el: pagRef.current,
                        clickable: true,
                    }}
                    spaceBetween={0}
                    slidesPerView={1}
                    // autoHeight={true}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    onSwiper={setContentSwiper}
                    onSlideChangeTransitionEnd={(swiper: any) => {
                        if (numRef && numRef.current) {
                            setActiveNum(swiper.realIndex + 1);
                        }
                    }}
                >
                    {content.map((post: any, index: any) => {
                        if (post.attributes.text) {
                            return (
                                <SwiperSlide key={`trending-slide-${index}`} data-num={index + 1}>
                                    {parse(post.attributes.text)}
                                </SwiperSlide>
                            )
                        } else {
                            return <></>
                        }
                    })}
                </Swiper>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default CarouselContent;